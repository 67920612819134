/* Discord-like colors for messages */
.chat {
    
}


.chat {
    font-family: Arial, sans-serif;
    font-size: 1.0em; /* Base font size scales with the width of the viewport */

    .message {
        display: flex;
        align-items: center;
        margin-bottom: 1em; /* Use em to scale margin with font size */
        border-radius: 1em; /* Scale border radius with font size */
        padding: 0.5em; /* Scale padding with font size */
        max-width: 100%; /* Message max width to fill the chat container */
        opacity: 0; /* Make messages initially invisible */
      }
    
      .left .text, .right .text {
        padding: 0.5em 1em; /* Scale padding with font size */
        min-width: 15%; /* Minimum width to encourage scaling */
      }
      
      .left .text {
        border-radius: 0 1em 1em 1em; /* Scale border radius with font size */
        background: linear-gradient(to bottom, #e9e9eb, #dcddde);

        color: #2e3338;
      }
      
      .right .text {
        border-radius: 1em 0 1em 1em; /* Scale border radius with font size */
        background: linear-gradient(to bottom, #5865f2, #4752c4);
        color: #fff;
        order: -1;
      }
    
      .right {
        justify-content: flex-end;
      }
      
      .avatar {
        width: 3em; /* Scale avatar size with font size */
        height: 3em; /* Scale avatar size with font size */
        border-radius: 50%;
        margin: 0 1em; /* Scale margin with font size */
        border: 2px solid #333;
      }
      .avatar-no-border {
        border: none;
      }
      
      .right .avatar {
        order: 2;
      }
      
      .text {
        padding: 8px 15px;
        min-width: 50px;
      }
    /* Add to or replace the relevant sections in the existing CSS */
    
    .typing {
        display: inline-block;
        font-size: 1.5em; /* Adjust size as needed */
        line-height: 1;
      }
      
      .typing::after {
        content: '';
        display: inline-block;
        animation: dotTyping 1.5s infinite step-start;
      }
      
      @keyframes dotTyping {
        0%, 20% {
          content: '.';
        }
        40% {
          content: '..';
        }
        60% {
          content: '...';
        }
        80%, 100% {
          content: '';
        }
      }
      
      /* Keep the rest of the CSS unchanged */
      
      
      @keyframes typing {
        from { width: 0 }
        to { width: 50px } /* Adjust width to match typing indicator size */
      }
      
      .message {
        animation: popup 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
      }
      
      @keyframes popup {
        from {
          opacity: 0;
          transform: translateY(20px);
        }
        to {
          opacity: 1;
          transform: translateY(0px);
        }
      }
}
  
  